import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import routes, { RouteDescription } from '@/common/routes';
import Layout from '@/components/Layout';
import ProLayout from '@/components/ProLayout';
import ProProvider, { createIntl } from '@ant-design/pro-provider'
import TokenContext from '@/utils/tokenContext';
import request from 'umi-request';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

import './styles/var.css';
import './styles/global.scss';
import useToken from './utils/useToken';
import allLocales from './utils/getAllLocales';
import IntlContext from './utils/intlContext';

type RouteItem = Omit<RouteDescription, 'routes'>;

const extractRoutes: (routes: RouteDescription[], ignorePaths?: string[]) => RouteItem[] = (routes, ignorePaths = []) => {
  const list: RouteItem[] = [];
  routes.forEach(route => {
    if (route.path) {
      if (ignorePaths.includes(route.path)) {
        return;
      }
      if (route.path.startsWith('http')) {
        return;
      }
      list.push(route);
    }
    if (route.routes) {
      const subList = extractRoutes(route.routes, ignorePaths);
      list.push(...subList);
    }
  });
  return list;
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { token } = useToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

type localType = 'zh-CN' | 'en-US';

function App() {
  const LazyLogin = React.lazy(() => import(`./pages/login`));
  const [token, setToken] = useState('');
  const [locale, setLocale] = useState<localType>('en-US');
  const tokenRef = useRef<string>();
  const [prepare, setPrepare] = useState(true);
  const values = useContext(ProProvider);
  
  useEffect(() => {
    const autoLoginVal = localStorage.getItem('autoLogin');
    let token: string;
    if (autoLoginVal === '1') {
      token = localStorage.getItem('lanToken') || '';
    } else {
      token = sessionStorage.getItem('lanToken') || '';
    }

    const userLanguage = localStorage.getItem('userLanguage');
    if (userLanguage === 'zh-CN' || userLanguage === 'en-US') {
      setLocale(userLanguage);
    }

    setToken(token);
    setPrepare(false);

    request.interceptors.request.use((url, options) => {
      const newParams: any = {
        ...options.params,
      };
      if (options.method === 'get') {
        newParams._t = Date.now();
      }
      return {
        url: url,
        options: {
          ...options,
          headers: {
            ...options.headers,
            token: tokenRef.current || '',
          },
          params: newParams,
        },
      };
    });

    request.interceptors.response.use(async (resp) => {
      const data = await resp.clone().json();
      if (data?.code === '90000') {
        setToken('');
      }
      return resp;
    });
  }, []);

  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  const updateToken = (token: string) => {
    setToken(token);
  }
  const changeLocale = (locale: localType) => {
    localStorage.setItem('userLanguage', locale);
    setLocale(locale);
  }

  if (prepare) {
    return null;
  }

  return (
    <div className="App">
      <TokenContext.Provider value={{
        value: {
          token,
        },
        actions: {
          updateToken,
        },
      }}>
        <IntlContext.Provider
          value={{
            value: {
              locale,
            },
            actions: {
              changeLocale: changeLocale,
            },
          }}
        >
          <IntlProvider locale={locale} messages={allLocales[locale].messages}
            onError={(err) => {
              if (err.code === "MISSING_TRANSLATION") {
                // console.warn("Missing translation", err.message);
                return;
              }
              throw err;
            }}
          >
            <ConfigProvider locale={allLocales[locale].config.antd}>
              <ProProvider.Provider
                value={{
                  ...values,
                  intl: createIntl(locale, allLocales[locale].config.antdPro),
                }}
              >
                <Routes>
                  <Route path="/login" element={(
                    <React.Suspense fallback={<>...</>}>
                      <LazyLogin />
                    </React.Suspense>
                  )}>
                  </Route>
                  <Route path="/" element={(
                    <RequireAuth>
                      <ProLayout locale={locale} changeLocale={changeLocale} />
                    </RequireAuth>
                  )}>
                    {extractRoutes(routes, ['/login']).map(route => {
                      const DynamicComponent = React.lazy(() => import(`./pages/${route.entryPath}`));
                      return (
                        <Route path={route.path} element={(
                          <React.Suspense fallback={<>...</>}>
                            <DynamicComponent />
                          </React.Suspense>
                        )}>
                        </Route>
                      )
                    })}
                  </Route>
                </Routes>
              </ProProvider.Provider>
            </ConfigProvider>
          </IntlProvider>
        </IntlContext.Provider>
      </TokenContext.Provider>
    </div>
  );
}

export default App;
