export interface RouteDescription {
  path?: string;
  iconName?: string;
  title: string;
  entryPath?: string;
  routes?: RouteDescription[];
  hideInMenu?: boolean;
  hideChildrenInMenu?: boolean;
}

const routes: RouteDescription[] = [
  {
    title: '登录',
    path: '/login',
    entryPath: 'login/index',
    hideInMenu: true,
  },
  {
    title: '首页',
    path: '/',
    iconName: 'HomeOutlined',
    entryPath: 'home/index',
    routes: [
      {
        title: '统计资料',
        path: '/',
        entryPath: 'home/index',
      },
      {
        title: '轮播图',
        path: '/homeBanner',
        entryPath: 'homeManage/banners',
      },
      {
        title: '楼层管理',
        path: '/homeFloor',
        entryPath: 'homeManage/floors',
      },
    ],
  },
  // {
  //   title: '模块管理',
  //   iconName: 'AppstoreOutlined',
  //   path: '/moduleResource',
  //   entryPath: 'moduleResource/index',
  //   routes: [
  //     {
  //       title: '模块列表',
  //       path: '/moduleResource/index',
  //       entryPath: 'moduleResource/index',
  //     },
  //     {
  //       title: '文本资源',
  //       path: '/moduleResource/text',
  //       entryPath: 'moduleResource/text',
  //     },
  //     {
  //       title: '图片资源',
  //       path: '/moduleResource/image',
  //       entryPath: 'moduleResource/image',
  //     },
  //   ]
  // },
  {
    title: '测试白名单',
    path: 'whiteManage',
    iconName: 'TeamOutlined',
    entryPath: 'whiteManage/index',
  },
  {
    title: '用户管理',
    iconName: 'TeamOutlined',
    routes: [
      {
        path: 'userManage',
        title: '注册用户',
        entryPath: 'userManage/index',
      },
      {
        path: 'userBadges',
        title: '用户徽章',
        entryPath: 'badgeManage/user',
      },
    ],
  },
  {
    title: '产品分类',
    path: '/goodsType',
    iconName: 'TagsOutlined',
    entryPath: 'goodsType/index',
    routes: [
      {
        title: '一级类目',
        path: '/goodsType',
        entryPath: 'goodsType/index',
        hideInMenu: true,
        routes: [
          {
            title: '二级类目',
            path: '/goodsType/:type1',
            entryPath: 'goodsType/type2',
          },
          {
            title: '分类产品列表',
            path: '/goodsType-products/:typeId',
            entryPath: 'goodsType/products',
          },
        ],
      },
    ],
  },
  {
    title: '产品管理',
    iconName: 'GiftOutlined',
    path: '/goodsManage',
    entryPath: 'goodsManage/index',
    routes: [
      {
        title: '购买链接',
        path: '/goodsManage-locations/:goodsId',
        entryPath: 'goodsManage/locations',
        hideInMenu: true,
      },
    ],
  },
  {
    title: '地点管理',
    iconName: 'ShopOutlined',
    path: '/locationManage',
    entryPath: 'locationManage/index',
  },
  {
    title: '文章管理',
    iconName: 'FileTextOutlined',
    path: '/articalManage',
    entryPath: 'articalManage/index',
    routes: [
      {
        title: '推荐产品',
        path: '/articalManage-products/:articalId',
        entryPath: 'articalManage/products',
        hideInMenu: true,
      },
    ],
  },
  {
    title: '礼遇专区',
    iconName: 'GiftOutlined',
    path: 'rewardManage',
    entryPath: 'rewardManage/index',
  },
  {
    title: '短信',
    iconName: 'MessageOutlined',
    routes: [
      {
        title: '短信模板',
        iconName: 'MessageOutlined',
        path: '/smsManage',
        entryPath: 'smsManage/index',
      },
      {
        title: '彩信模板',
        iconName: 'MessageOutlined',
        path: '/mmsManage',
        entryPath: 'mmsManage/index',
      },
      {
        title: '发送任务管理',
        iconName: 'MessageOutlined',
        path: '/mmsManageList',
        entryPath: 'mmsManage/list',
      },
      {
        title: '新建发送任务',
        iconName: 'SendOutlined',
        path: '/smsSend',
        entryPath: 'smsManage/send',
      },
    ],
  },
  {
    title: '评价管理',
    iconName: 'CommentOutlined',
    path: '/reviewManage',
    entryPath: 'reviewManage/index',
  },
  {
    title: '徽章管理',
    iconName: 'SmileOutlined',
    path: '/badgeManage',
    entryPath: 'badgeManage/index',
  },
  {
    title: '数据分析',
    iconName: 'FundOutlined',
    path: 'https://wedata.weixin.qq.com/mp/basic-data/core-data',
  },
  // {
  //   title: '星品体验馆',
  //   iconName: 'ShopOutlined',
  //   path: '/experienceManage',
  //   entryPath: 'experienceManage/index',
  // },
];

export default routes;
