import React from 'react';
import * as icons  from '@ant-design/icons';
import { Route } from '@ant-design/pro-layout/lib/typings';
import routes, { RouteDescription } from '@/common/routes';

const convertRoutes: (routes: RouteDescription[]) => Route[] = (routes) => {
  return routes.map(route => {
    const IconComponent = route.iconName && route.iconName in icons ? (icons as any)[route.iconName] : icons.BorderOutlined;
    const newRoute: Route = {
      name: route.title,
      path: route.path,
      icon: <IconComponent />,
      hideInMenu: route.hideInMenu,
      hideChildrenInMenu: route.hideChildrenInMenu,
    };
    if (route.routes) {
      newRoute.routes = convertRoutes(route.routes);
    }
    return newRoute;
  });
}

const menuRoutes = convertRoutes(routes);

export default {
  logo: '/LNC_golden_logo.png',
  title: '',
  route: {
    path: '/',
    routes: menuRoutes,
  } as Route,
  location: {
    pathname: '/',
  },
};
