import { useIntl } from 'react-intl';

export const useFormatMessage = (id: string, defaultMessage: string, values: any = {}) => {
  const intl = useIntl();
  return intl.formatMessage({
    id,
    defaultMessage,
  }, values);
}

/**
 * 字符串解析成json对象
 * @param {string} str json字符串
 * @param {array|object} [defaultValue={}] 解析失败默认值
 */
export const parseJSON = (str: string, defaultValue = {}) => {
  let json;
  if (str) {
    try {
      json = JSON.parse(str);
    } catch (err) {
      json = defaultValue;
    }
    return json;
  }
  return defaultValue;
};

export const replace = (str: string, data: any) => {
  if (str) {
    return str.replace(/\{\{([\w_]+)\}\}/g, function (...args) {
      const key = args[1];
      return data[key] || '';
    });
  }
  return '';
};
