import { createContext } from "react";

type ContextType = {
  value: {
    locale: string,
  };
  actions: {
    [key: string]: any,
  };
}

const IntlContext = createContext<ContextType>({
  value: {
    locale: '',
  },
  actions: {},
});

export default IntlContext;
