import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Result, Avatar, Space, Statistic } from 'antd';
import { LikeOutlined, UserOutlined } from '@ant-design/icons';
import { Outlet, Link, useNavigate } from 'react-router-dom';

import type { BasicLayoutProps, ProSettings } from '@ant-design/pro-layout';
import ProLayout, { PageContainer, SettingDrawer } from '@ant-design/pro-layout';
import defaultProps from './_defaultProps';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TableDropdown } from '@ant-design/pro-table';
import { useFormatMessage } from '@/utils/stringUtils';

type PropsType = {
  locale: BasicLayoutProps['locale'];
  changeLocale: any;
}

export default (props: PropsType) => {
  const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({ fixSiderbar: true });
  const [pathname, setPathname] = useState(window.location.hash.slice(1) || '/');
  const intl = useIntl();
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);
  const logoutBtnText = useFormatMessage('loginForm.注销', '注销');
  return (
    <div
      id="test-pro-layout"
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        {...defaultProps}
        locale={props.locale}
        formatMessage={(md) => {
          return intl.formatMessage({
            id: md.id,
            defaultMessage: md.defaultMessage,
          });
        }}
        location={{
          pathname,
        }}
        waterMarkProps={{
          content: 'Lancaster',
        }}
        breadcrumbProps={{
          itemRender: (route, params, routes, paths) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link
                to={route.path}
              >{route.breadcrumbName}</Link>
            );
          }
        }}
        onMenuHeaderClick={(e: any) => console.log(e)}
        menuItemRender={(item: any, dom: any) => {
          if (item.path?.startsWith('http')) {
            return (
              <a
                href={item.path}
              >
                {dom}
              </a>
            )
          }
          return (
            <Link
              to={item.path || '/'}
            >
              {dom}
            </Link>
          )
        }}
        rightContentRender={() => (
          <Space>
            <Button
              type="text"
              onClick={props.changeLocale.bind(this, props.locale === 'en-US' ? 'zh-CN' : 'en-US')}
            >{props.locale === 'en-US' ? '中 文' : 'ENGLISH'}</Button>
            <TableDropdown
              key="actionGroup"
              onSelect={(key) => {
                if (key === 'logout') {
                  navigate('/login', { replace: true });
                  if (localStorage.getItem('autoLogin') === '1') {
                    localStorage.removeItem('lanToken');
                  } else {
                    sessionStorage.removeItem('lanToken');
                  }
                }
              }}
              menus={[
                { key: 'logout', name: logoutBtnText },
              ]}
            >
              <Space>
                <Avatar shape="square" size="small" icon={<UserOutlined />} />
                {localStorage.getItem('userName')}
              </Space>
            </TableDropdown>
          </Space>
        )}
        {...settings}
      >
        <Outlet />
      </ProLayout>
      {/* <SettingDrawer
        pathname={pathname}
        getContainer={() => document.getElementById('test-pro-layout')}
        settings={settings}
        onSettingChange={(changeSetting) => {
          setSetting(changeSetting);
        }}
        disableUrlParams
      /> */}
    </div>
  );
};
