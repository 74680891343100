import { merge } from 'lodash';

import zhCNMessages, { antdPro as zhCNAntdPro } from '@/locales/zh-CN';
import enUSMessage, { antdPro as enUSAntdPro } from '@/locales/en-US';

import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';

import enUSPro from '@ant-design/pro-provider/es/locale/en_US';
import zhCNPro from '@ant-design/pro-provider/es/locale/zh_CN';

export default {
  'zh-CN': {
    locale: 'zh-CN',
    messages: zhCNMessages,
    config: {
      antd: zhCN,
      antdPro: merge(zhCNPro, zhCNAntdPro),
    },
  },
  'en-US': {
    locale: 'en-US',
    messages: enUSMessage,
    config: {
      antd: enUS,
      antdPro: merge(enUSPro, enUSAntdPro),
    },
  }
}
