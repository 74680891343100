import { useContext } from "react";
import TokenContext from './tokenContext';

export default function useToken() {
  const ctx = useContext(TokenContext);
  return {
    token: ctx.value.token,
    updateToken: ctx.actions?.updateToken,
  }
}
