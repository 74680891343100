import { createContext } from "react";

type ContextType = {
  value: {
    token: string,
  };
  actions: {
    [key: string]: any,
  };
}

const TokenContext = createContext<ContextType>({
  value: {
    token: '',
  },
  actions: {},
});

export default TokenContext;
